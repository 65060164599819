import React, { useEffect } from "react";
import { graphql } from "gatsby";

import Footer from "../components/Footer/Footer.jsx";
import CTABanner from "../components/CTABanner/CTABanner.jsx";
import { Seo } from "../components/SEO/Seo.jsx";
import MainMenu from "../components/MainMenu/MainMenu.jsx";

import CheckmarkImg from "../images/checkmark.svg";

import OpenPositionsSection from "../components/Careers/components/OpenPositionsSection";

import AOS from "aos";
import "aos/dist/aos.css";

const OffersSingle = ({ data }) => {
  const { position, jobResponsibilitiesText } = data.mdx.frontmatter;

  useEffect(() => {
    AOS.init({
      duration: 1200,
      once: true,
    });
  }, []);

  return (
    <main>
      <Seo
        title={position + " | Gammasoft: Custom Software Development Company"}
        description={"Check out our offer for the position of" + position}
      />
      <MainMenu />
      <section className="offers-single-section">
        <div className="offers-single-section-content container">
          <div className="offers-single-section-content-left">
            <span>
              Career at <span>GammaSoft</span>
            </span>
            <h1 className="heading-primary">{position}</h1>
            <p className="paragraph-primary">
              Work at GammaSoft as a {position} and create applications for our
              foreign and local clients
            </p>
            <h2 className="heading-secondary" data-aos="fade-up">
              Job Responsibilities
            </h2>
            <p
              className="paragraph-primary"
              data-aos="fade-up"
              data-aos-delay={50}
            >
              {jobResponsibilitiesText}
            </p>
            <h2
              className="heading-secondary"
              data-aos="fade-up"
              data-aos-delay={100}
            >
              Requirements
            </h2>
            <ul
              className="offers-single-section-content-left-list"
              data-aos="fade-up"
              data-aos-delay={350}
            >
              {data.mdx.frontmatter.requirements.map((offer, index) => (
                <li key={index} className="paragraph-primary">
                  <img src={CheckmarkImg} alt="" />
                  {offer.text}
                </li>
              ))}
            </ul>
            <h2
              className="heading-secondary"
              data-aos="fade-up"
              data-aos-delay={200}
            >
              Nice to have
            </h2>
            <ul
              className="offers-single-section-content-left-list"
              data-aos="fade-up"
              data-aos-delay={350}
            >
              {data.mdx.frontmatter.niceToHave.map((offer, index) => (
                <li key={index} className="paragraph-primary">
                  <img src={CheckmarkImg} alt="" />
                  {offer.text}
                </li>
              ))}
            </ul>
            <h2
              className="heading-secondary"
              data-aos="fade-up"
              data-aos-delay={300}
            >
              What we offer
            </h2>
            <ul
              className="offers-single-section-content-left-list"
              data-aos="fade-up"
              data-aos-delay={350}
            >
              {data.mdx.frontmatter.whatWeOffer.map((offer, index) => (
                <li key={index} className="paragraph-primary">
                  <img src={CheckmarkImg} alt="" />
                  {offer.text}
                </li>
              ))}
            </ul>
          </div>
          <div className="offers-single-section-content-right">
            <div className="offers-single-section-content-right-block">
              <h2 className="heading-secondary">Apply today</h2>
              <p className="paragraph-primary">
                Work at Gammasoft as a {position} and create applications for
                our foreign and local clients
              </p>
              <ul className="offers-single-section-content-right-benefits-list">
                <li>
                  <img src={CheckmarkImg} alt="" />
                  B2B contract or UoP
                </li>
                <li>
                  <img src={CheckmarkImg} alt="" />
                  Remote work
                </li>
                <li>
                  <img src={CheckmarkImg} alt="" />
                  Working comfort
                </li>
                <li>
                  <img src={CheckmarkImg} alt="" />
                  Great work autonomy
                </li>
              </ul>
              <p className="paragraph-primary">
                When applying, indicate in the title the name of the position
                for which you are applying and in the body attach your CV. Good
                luck!
              </p>
              <div className="apply-btn">
                <a
                  className="btn btn-secondary"
                  href="mailto: jobs@gammasoft.pl"
                >
                  <span>
                    Apply now
                    <svg
                      version="1.1"
                      id="Layer_1"
                      xmlns="http://www.w3.org/2000/svg"
                      x="0px"
                      y="0px"
                      viewBox="0 0 36.1 25.8"
                    >
                      <g>
                        <line
                          fill="none"
                          stroke="#FFFFFF"
                          strokeWidth="3"
                          x1="0"
                          y1="12.9"
                          x2="34"
                          y2="12.9"
                        ></line>
                        <polyline
                          fill="none"
                          stroke="#FFFFFF"
                          strokeWidth="3"
                          points="22.2,1.1 34,12.9 22.2,24.7   "
                        ></polyline>
                      </g>
                    </svg>
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
        <OpenPositionsSection />
      </section>
      <CTABanner />
      <Footer />
    </main>
  );
};

export default OffersSingle;

export const query = graphql`
  query OffersSingle($slug: String!) {
    mdx(frontmatter: { slug: { eq: $slug } }) {
      body
      frontmatter {
        position
        jobResponsibilitiesText
        whatWeOffer {
          text
        }
        niceToHave {
          text
        }
        requirements {
          text
        }
      }
    }
  }
`;

import React from "react";
import { Link, graphql, useStaticQuery } from "gatsby";
import ArrowButton from "../../ArrowButton";
import TriangleImg from "../../../images/triangle-img.svg";

const OpenPositionsSection = () => {
  const data = useStaticQuery(graphql`
    query OfferSingle {
      allMdx(limit: 5, filter: { fields: { source: { eq: "offers" } } }) {
        nodes {
          fields {
            source
          }
          frontmatter {
            slug
            position
            infoAboutPosition
          }
          id
        }
      }
    }
  `);

  const offersList = data.allMdx.nodes.filter(
    node => node.fields.source === "offers"
  );

  return (
    <section className="open-positions-section" id="open-positions">
      <img src={TriangleImg} alt="" />
      <div className="open-positions-section-content container">
        <h2 className="heading-secondary">Open positions</h2>
        <p className="paragraph-primary">
          Look at the positions we are currently hiring for.
        </p>
      </div>
      <div className="open-positions-section-list container">
        {offersList.map(offerListItem => {
          return (
            <div className="open-positions-section-list-item">
              <div className="open-positions-section-list-item-left">
                <h3 className="heading-third">
                  {offerListItem.frontmatter.position}
                </h3>
                <span className="open-position-info">
                  {offerListItem.frontmatter.infoAboutPosition}
                </span>
              </div>
              <div className="open-positions-section-list-item-right">
                <Link
                  to={"/offers/" + offerListItem.frontmatter.slug}
                  key={offerListItem.id}
                  className="btn btn-secondary"
                >
                  <ArrowButton text="More details" />
                </Link>
              </div>
            </div>
          );
        })}
      </div>
      <div className="open-positions-section-btn container">
        <a href="/offers/" className="btn btn-fourth">
          <span>
            Check all positions
            <svg
              version="1.1"
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              viewBox="0 0 36.1 25.8"
            >
              <g>
                <line
                  fill="none"
                  stroke="#136288"
                  strokeWidth="3"
                  x1="0"
                  y1="12.9"
                  x2="34"
                  y2="12.9"
                ></line>
                <polyline
                  fill="none"
                  stroke="#136288"
                  strokeWidth="3"
                  points="22.2,1.1 34,12.9 22.2,24.7   "
                ></polyline>
              </g>
            </svg>
          </span>
        </a>
      </div>
    </section>
  );
};

export default OpenPositionsSection;
